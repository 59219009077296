import request from '@/utils/request'


// 查询用户标签列表
export function listLabel(query) {
  return request({
    url: '/user/label/list',
    method: 'get',
    params: query
  })
}

// 查询用户标签分页
export function pageLabel(query) {
  return request({
    url: '/user/label/page',
    method: 'get',
    params: query
  })
}

// 查询用户标签详细
export function getLabel(data) {
  return request({
    url: '/user/label/detail',
    method: 'get',
    params: data
  })
}

// 新增用户标签
export function addLabel(data) {
  return request({
    url: '/user/label/add',
    method: 'post',
    data: data
  })
}

// 修改用户标签
export function updateLabel(data) {
  return request({
    url: '/user/label/edit',
    method: 'post',
    data: data
  })
}

// 删除用户标签
export function delLabel(data) {
  return request({
    url: '/user/label/delete',
    method: 'post',
    data: data
  })
}
